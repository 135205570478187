import { Container } from "@mui/material";
import { Chart, ChartData, ChartItem } from "chart.js";
import { useEffect, useRef, useState } from "react";
import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from "@faker-js/faker";
import { useLazyQuery } from "@apollo/client";
import { DataTypeEnum, PostOfferAnalysisDocument } from "../../gql/graphql";
import { useAsyncEffect } from "../../utils/ReactHelpers";
import { useTranslateField } from "../../appContext/AppContext";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

export interface DatasetsDataInterface {
    label: string
    data: Array<string>
    borderColor: string[]
    backgroundColor: string[]
}

export function OfferPostAnalysis() {
    const [postOfferAnalysis,] = useLazyQuery(PostOfferAnalysisDocument)
    const translate = useTranslateField()
    const [labels, setLabels] = useState<Array<string>>([])
    const [datasets, setDatasets] = useState<Array<DatasetsDataInterface>>([])
    useAsyncEffect(async () => {
        let response = await postOfferAnalysis({
            variables: {
                data: {
                    type: DataTypeEnum.Day
                }
            }
        })

        if (response.data) {
            let sortedData = response.data.PostOfferAnalysis.data.map((data: any, index:number) => {
                const label = response.data?.PostOfferAnalysis.labels[index];
                return {
                    label: label,
                    data: data,
                };

            }).sort((a: any, b: any) => {
                return new Date(a.label).getTime() - new Date(b.label).getTime();
            });

            const sortedLabels = sortedData.slice(-31).map((data: any) => {
                return data.label;
            });

            const sortedDatas = sortedData.slice(-31).map((data: any) => {
                return data.data;
            });

            console.log("response.data=", response.data)
            setLabels([...sortedLabels])
            let color = faker.color.rgb()
            setDatasets([
                {
                    label: translate('Post offer'),
                    data: [...sortedDatas],
                    borderColor: [color],
                    backgroundColor: [color + "9f"],
                }
            ])
        }
    }, [])


    return (
        <div>
            <Line
                options={
                    {
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top' as const,
                            },
                            title: {
                                display: true,
                                text: translate('Post offer analysis'),
                            },
                        },
                    }
                }
                data={
                    {
                        labels: labels,
                        datasets: datasets,
                    }
                } />
        </div>
    )
}